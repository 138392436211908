import { render, staticRenderFns } from "./exchangeList.vue?vue&type=template&id=dff6dd98&scoped=true"
import script from "./exchangeList.vue?vue&type=script&lang=js"
export * from "./exchangeList.vue?vue&type=script&lang=js"
import style0 from "./exchangeList.vue?vue&type=style&index=0&id=dff6dd98&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff6dd98",
  null
  
)

export default component.exports