<template>
  <!-- 卡密 -兑换列表-->
  <div class="pageContol listWrap exchangeList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">卡密管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">卡密兑换列表 </a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="兑换单号" class="searchboxItem ci-full">
              <span class="itemLabel">兑换单号:</span>
              <el-input
                size="small"
                v-model="queryData.convertNo"
                type="text"
                placeholder="请输入兑换单号"
                clearable
              />
            </div>
            <div title="商品名称" class="searchboxItem ci-full">
              <span class="itemLabel">商品名称:</span>
              <el-input
                size="small"
                v-model="queryData.resourceName	"
                type="text"
                placeholder="请输入商品名称"
                clearable
              />
            </div>
            <div title="兑换人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 10rem">兑换人姓名:</span>
              <el-input
                size="small"
                v-model="queryData.customerName"
                type="text"
                placeholder="请输入兑换人姓名"
                clearable
              />
            </div>
            <div title="兑换人手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 10rem">兑换人手机号:</span>
              <el-input
                size="small"
                v-model="queryData.mobile"
                type="text"
                placeholder="请输入兑换人手机号"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
              <div title="所属机构" class="searchboxItem ci-full">
              <span class="itemLabel" >所属机构:</span>
              <el-input
                size="small"
                v-model="queryData.compName"
                type="text"
                placeholder="请输入所属机构"
                clearable
              />
            </div>
            <div title="兑换日期" class="searchboxItem ci-full">
              <span class="itemLabel">兑换日期:</span>
              <el-date-picker
                v-model="queryData.exchangeDate"
                type="datetimerange"
                size="small"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                min-width="60"
              ></el-table-column>
              <el-table-column
                label="兑换单号"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="convertNo"
              ></el-table-column>
              <el-table-column
                label="所属机构"
                align="left"
                show-overflow-tooltip
                min-width="160"
                prop="compName"
              ></el-table-column>
              <el-table-column
                label="商品名称"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="resourceName"
              ></el-table-column>
              <el-table-column
                label="兑换人姓名"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="customerName"
              ></el-table-column>
              <el-table-column
                label="兑换人手机号"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="mobile"
              ></el-table-column>
              <el-table-column
                label="兑换卡号"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="keyNo"
              ></el-table-column>
              <el-table-column
                label="兑换卡密"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="keyPwd"
              ></el-table-column>
              <el-table-column
                label="兑换日期"
                align="left"
                show-overflow-tooltip
                min-width="100"
                prop="useTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.useTime | moment }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "exchangeList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  beforeRouteLeave: resetKeepAlive,
  data() {
    return {
      // 查询数据
      queryData: {
        convertNo: "", // 兑换单号
        resourceName: "", // 商品名称
        customerName: "", // 兑换人姓名
        mobile: "", // 兑换人手机号
        exchangeDate: "", // 兑换日期
        compName:'', //所属机构
      }
    };
  },
  watch: {},
  methods: {
    // 获取发放卡密列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        convertNo: this.queryData.convertNo,
        resourceName: this.queryData.resourceName,
        customerName: this.queryData.customerName,
        mobile: this.queryData.mobile,
        compName: this.queryData.compName,
      };
      if (this.queryData.exchangeDate) {
        params.startDate = this.queryData.exchangeDate[0];
        params.endDate = this.queryData.exchangeDate[1];
      }
      this.doFetch(
        {
          url: "/biz/card/key/queryConvertList",
          params,
          pageNum,
        },
        true,
        2
      );
    }
  },
  computed: {},
  mounted() {},
  created() {},
};
</script>
  
 <style lang="less" scoped>
.exchangeList {
}
</style>
  